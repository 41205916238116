@font-face {
  font-family: 'PP Editorial New';
  src: url("fonts/PPEditorialNew-Regular.otf") format('opentype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'PP Editorial New';
  src: url("fonts/PPEditorialNew-Ultralight.otf") format('opentype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'DM Sans';
  src: url("fonts/DMSans-Bold.ttf") format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'DM Sans';
  src: url("fonts/DMSans-BoldItalic.ttf") format('truetype');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'DM Sans';
  src: url("fonts/DMSans-Regular.ttf") format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'DM Sans';
  src: url("fonts/DMSans-Italic.ttf") format('truetype');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'DM Sans';
  src: url("fonts/DMSans-Medium.ttf") format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'DM Sans';
  src: url("fonts/DMSans-MediumItalic.ttf") format('truetype');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'DM Sans';
  src: url("fonts/DMSans-Light.ttf") format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'DM Sans';
  src: url("fonts/DMSans-LightItalic.ttf") format('truetype');
  font-style: italic;
  font-weight: 300;
}

body {
  font-family: 'DM Sans', sans-serif;
}
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.home__section-title,
.title-decorator {
  font-family: 'PP Editorial New', serif;
}
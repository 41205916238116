// This is a file that can be overridden by the application in order to override styles
// Notice that this file is included at the very end of the stylesheets packs to have
// more priority
//
// To override the Tailwind CSS configuration you can do so by following the instructions
// available at https://docs.decidim.org/en/develop/customize/styles.html#_tailwind_css
//
// By default this is empty.

@import "../partials/fonts";

.main-bar__links-desktop {
    @media (min-width: 768px){
      [data-target*=dropdown] {
        @apply block min-w-16;;
        div {
          @apply block text-secondary text-sm text-center;
          svg {
            @apply inline-block;
          }
        }
      }
    }
    .main-header__language-chooser {
      @apply relative z-10;
  
      > ul {
        @apply absolute left-0 bg-white p-4 shadow-md text-left;
        >li {
          @apply text-secondary text-md hover:underline hover:bg-secondary hover:text-white transition;
          >a {
            @apply p-2 w-full block;
          }
        }
      }
    }
  }